// require('./bootstrap');



window.callAjax = function(url, method, data, button = null) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    if (button != null) {
        button.disabled = true;
    }

    return jQuery.ajax({
        url: url + '?api_token=' + global_api_token,
        method: method,
        data : data,
        success: function(result) {
            if (button != null) {
                button.disabled = false;
            }

            return result;
        },
        error: function (result) {
            if (button != null) {
                button.disabled = false;
            }

            if (typeof result == 'object') {
                if (result.responseJSON.errors) {
                    if (result.responseJSON.errors) {
                        Object.values(result.responseJSON.errors).forEach(val => {
                            for (i = 0; i < val.length; i++) {
                                toastr.error(val[i]);
                            }
                        });
                    }
                }
                else if (result.responseJSON.message) {
                    toastr.error(result.responseJSON.message);
                }
            }
            return null;
        }
    });
}

$('.modal-clicky').on('click', function(e) {
    e.preventDefault();
    var target = $(this).data('target');
    $(target).appendTo("body").modal('show');
});

window.showError = function (input, errors) {
    if (typeof input != 'undefined' && !input.hasClass('is-invalid')) {
        input.addClass('is-invalid');
        input.parent().addClass('has-error');

        var errorContainer = input.parent().find('.help-block.invalid-feedback');
        if (typeof errorContainer != 'undefined') {
            for (var i = 0; i < errors.length; i++) {
                errorContainer.append('<strong>' + errors[i] + '</strong><br />');
            }
        }
    }
}

window.hideError = function (input) {
    if (typeof input != 'undefined') {
        input.removeClass('is-invalid');
        input.parent().removeClass('has-error');
        input.parent().find('.help-block.invalid-feedback').html('');
    }
}

$('.and-more-tictac').on('click', function(e) {
    e.preventDefault();

    var button = $(this);
    var attendeeCount = $(this).data('attendee-count');
    $('.extra-attendees').stop().slideToggle('', function() {
        if ($('.extra-attendees').is(':visible')) {
            $(button).html(attendeeCount + ' anderen verbergen');
        }
        else {
            $(button).html('En ' + attendeeCount + ' anderen');
        }
    });
});

// window.callAjax = callAjax();


